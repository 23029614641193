<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Dashboard</ion-title>
      </ion-toolbar>
    </ion-header>
   
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <strong class="capitalize">{{ $route.params.id }}</strong>  
        <ion-button id="log" v-if="$store.state.userRole==7"  @click="$router.push('/folder/access-log')">Log accessi</ion-button>
        <div style="height:100px">
        </div>
      
        <div v-if="vediTimbr==1">
            <ion-item>
              <div v-if="attendance.verso==4 &&  attendance.date==today">
          
              </div>
              <div v-else>
                <img src="images\entrata.PNG" @click="entrata()" alt="ENTRATA" v-if="attendance.verso==2 || attendance.verso==0 || attendance.verso==4">
                <img src="images\uscita.PNG" @click="uscita()" alt="USCITA"  v-if="attendance.verso==1 || attendance.verso==3">
              </div>
            </ion-item>
        </div>

        <br>
        <div v-if="vediRegAtt==1" style="display:block; margin:1%;">
            <ion-button class="bottoni" style="--ion-color-primary:#2ca830" color="primary" @click="$router.push('/folder/registrazione-attivita')">Vai a registra attività</ion-button> 
        </div>
        <br>

        <div id="dtable" v-if="calendars!='' && vediProg==1">
          <h4>Le tue scadenze di oggi:</h4>
          <dtable          
            color="#fff"
            bgcolor="rgb(56,128,255)"
            :heading="calendarHeading"
            :data="calendars"
            :total="table.total"
            :perPage="table.perPage"
            :currentPage="table.currentPage"
            :orderBy="table.orderBy"
            :order="table.order"
            @changePage="getScadenze"
          >
          </dtable>
          <ion-button id="btndtable" @click="$router.push('/folder/calendar')">VAI AL TUO CALENDARIO</ion-button>
       </div>

       <br>

        <div id="dtable" v-if="attivita!='' && vediRepAtt==1">
          <h4>Le tue attività:</h4>
          <dtable          
            color="#fff"
            bgcolor="rgb(56,128,255)"
            :heading="attivitaHeading"
            :data="attivita"
            :total="table.total"
            :perPage="table.perPage"
            :currentPage="table.currentPage"
            :orderBy="table.orderBy"
            :order="table.order"
            @changePage="getAttivita"
          >
          </dtable>
          <ion-button id="btndtable" @click="$router.push('/folder/report-attivita')">VAI ALLE ATTIVITA'</ion-button>
       </div>

       <br>

        <div id="dtable" v-if="holidays!='' && vediRepPerm==1">
          <h4>I tuoi permessi di oggi:</h4>
          <dtable          
            color="#fff"
            bgcolor="rgb(56,128,255)"
            :heading="holidaysHeading"
            :data="holidays"
            :total="table.total"
            :perPage="table.perPage"
            :currentPage="table.currentPage"
            :orderBy="table.orderBy"
            :order="table.order"
            @changePage="getPermessi"
          >
          </dtable>
          <ion-button id="btndtable" @click="$router.push('/folder/report-permessi')">VAI AI TUOI PERMESSI</ion-button>
        </div>

        <br>

        <div id="dtable" v-if="contracts!='' && vediRepContr==1">
          <h4>Contratti attivi:</h4>
          <dtable          
            color="#fff"
            bgcolor="rgb(56,128,255)"
            :heading="contractHeading"
            :data="contracts"
            :total="table.total"
            :perPage="table.perPage"
            :currentPage="table.currentPage"
            :orderBy="table.orderBy"
            :order="table.order"
            @changePage="getContratti"
          >
          </dtable>
          <ion-button id="btndtable" @click="$router.push('/folder/report-contratti')">VAI AI CONTRATTI</ion-button>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton,
    IonButtons, 
    IonContent, 
    IonHeader,
    IonItem, 
    IonMenuButton, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    toastController
  } from '@ionic/vue';
  import Dtable from './partials/Dtable';

  export default {
    name: 'Folder',
    components: {
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonItem,
      IonMenuButton,
      IonPage,
      IonTitle,
      IonToolbar,
      Dtable
    },
    data : function(){
      return{
        calendarHeading: [
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
          {
            text: 'Task',
            code: 'task'
          },         
        ],
        contractHeading: [
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
        ],
        holidaysHeading: [
          {
            text: 'Tipologia',
            code: 'type'
          },
          {
            text: 'Data di inizio',
            code: 'begindate'
          },
          {
            text: 'Data di fine',
            code: 'enddate'
          },
          {
            text: 'Orario di inizio',
            code: 'begintime'
          },
          {
            text: 'Orario di fine',
            code: 'endtime'
          },
        ],
        attivitaHeading: [
          {
            text: 'Rif.',
            code: 'ref_off'
          },
          {
            text: 'Cliente',
            code: 'account'
          },
        ],
        calendars:[],
        holidays:[],
        contracts:[],
        attivita:[],
        calendar:{
          user:'',
          ref_off:'',
          task:'',
          date:'',
        },
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'id',
          order: 'asc',
        },
        idoneityToday:'',
        vediTimbr:'',
        vediRegAtt:'',
        vediRepContr:'',
        vediRepPerm:'',
        vediRepAtt:'',
        vediProgAtt:'',
        attendances:[],
        attendance:{
          user:'',
          date:'',
          time:'',
          verso:'',
        },
        today:'',
      }
    },
    methods:{
      getUser(){
        this.axios.post(this.apiUrl + 'get-user', {}, this.headers)
        //.then(async (response) => {
        //  console.log(response)
        //})
      },
      getScadenze(obj=false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-scadenze', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.calendars=response.data.calendars.data
            this.table.total = response.data.calendars.total
            this.table.perPage = response.data.calendars.per_page        
          }
        })
      },
      getPermessi(obj=false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-today-holidays', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.holidays=response.data.holidays.data
            this.table.total = response.data.holidays.total
            this.table.perPage = response.data.holidays.per_page        
          }
        })
      },
      getContratti(obj=false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-contracts-attivi', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.contracts=response.data.contracts.data
            this.table.total = response.data.contracts.total
            this.table.perPage = response.data.contracts.per_page        
          }
        })
      },
      getAttivita(obj=false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-attivita', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.attivita=response.data.attivita.data
            this.table.total = response.data.attivita.total
            this.table.perPage = response.data.attivita.per_page        
          }
        })
      },
      getTodayIdoneity(){
        this.axios.post(this.apiUrl + 'get-idoneity-today', {}, this.headers)
        .then(async (response) => {
          this.idoneityToday=response.data.idoneityToday;

          if(this.idoneityToday!=''){
            this.$router.push('/folder/idoneity');
          }
        })
      },
    getUserInfo(){
        this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
        .then(async (response) => {
          //console.log(response)
          this.user=response.data.user;

          this.vediTimbr=response.data.user.vediBadge;
          this.vediProgAtt=response.data.user.vediProg;
          this.vediRegAtt=response.data.user.vediRegAtt;
          this.vediRepAtt=response.data.user.vediRepAtt;
          this.vediRepPerm=response.data.user.vediRepPerm;
          this.vediRepContr=response.data.user.vediRepContr;
          
          //console.log(this.user);
        })
      },
      getLastAttendance(){
      this.axios.post(this.apiUrl + 'get-last-attendance', {}, this.headers)
      .then(async (response) => {
          if(response.data.status_code==200){
              this.attendance=response.data.attendance   
              this.today=response.data.today
              //console.log(this.attendance.verso);
          }
      })
    },
    async entrata(){
      this.getLastAttendance();
      if(this.attendance.verso==1){
        const toast = await toastController
        .create({
            color: 'warning',
            message: 'Dovresti effettuare un\'uscita',
            duration: 2000
        })
        return toast.present();
      }else{
        this.attendance.verso=1;
        this.axios.post(this.apiUrl + 'entrata-attendance', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.attendance.user='';
            this.attendance.date='';
            this.attendance.time='';
            const toast = await toastController
            .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      }
    },
    async uscita(){
      this.getLastAttendance();
      if(this.attendance.verso==2){
        const toast = await toastController
        .create({
            color: 'warning',
            message: 'Dovresti effettuare un\'entrata',
            duration: 2000
        })
        return toast.present();
      }else{
        this.attendance.verso=2;
        this.axios.post(this.apiUrl + 'uscita-attendance', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.attendance.user='';
            this.attendance.date='';
            this.attendance.time='';
            this.attendance.verso='';
            const toast = await toastController
            .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      }
    }
    },
    ionViewWillEnter: function(){
      //this.getTodayIdoneity();
      this.getUser();
      this.getScadenze();
      this.getPermessi();
      this.getContratti();
      this.getUserInfo();
      this.getAttivita();
      this.getLastAttendance();
    }
  }
</script>

<style scoped>
  #container {
    min-height:100%;
    display:block;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
  }
  #log{
    float:right;
    margin:15px;
  }
  #dtable{
    width:40%;
    margin:15px;
  }

  @media only screen and (max-width: 600px) {
    #dtable{
      width:90%;
    }
    #btndtable{
      width:100%;
    }

  .bottoni{
    width:100%;
    margin-top:2%;
  }
  }
</style>